import React, { useEffect, useRef, useState } from "react";
import "./style.css"
import gif1 from "../../../Assets/background/ytVideo1.gif"

let useClickOutside = (handler) => {
    let domNode = useRef();

    useEffect(() => {
        let maybeHandler = (event) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", maybeHandler);

        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });
    return domNode;
};

const HomePageVideoSection = () => {
    const [videoUrl, setVideoUrl] = useState("")
    const [videoOpen, setvideoOpen] = useState(false);

    let domNode = useClickOutside(() => {
        setvideoOpen(false);
    });
    const OpenModal = (check) => {
        setvideoOpen(true)
        if (check === "VideoOne") {
            setVideoUrl("https://www.youtube.com/embed/3ajhFsIfAuE?si=HYdjeSYZF-uipktQ")
        } else if (check === "VideoTwo") {
            setVideoUrl("https://www.youtube.com/embed/EwGMYHSAAEg?si=FOtFeZRHuLPnLZK8")
        } else if (check === "VideoThree") {
            setVideoUrl("https://www.youtube.com/embed/xEASA0KVEWQ?si=8_pzQ4dl6BYY_KcA")
        } else if (check === "VideoFour") {
            setVideoUrl("https://www.youtube.com/embed/4QrScHQXx_M?si=jfvPlVQNZSy7LeTO")
        } else if (check === "VideoFive") {
            setVideoUrl("https://www.youtube.com/embed/w-sC2PRMhJM?si=tfq6cayBJ55OKsMa")
        }

    }
    return (
        <div ref={domNode} className="py-[5%]">
            <div className='lg:flex px-[5%]'>
                <div className="lg:w-[40%] relative py-4 ">
                    <div className="sticky top-[10%]">
                        <h3 className="text-4xl">Building Strong Foundations</h3>
                        <h5 className="text-xl">Bridging Knowledge and Success</h5>
                        {/* <p className='lg:w-[70%] pt-4'><q>The Pessimist Sees Difficulty In Every Opportunity. The Optimist Sees Opportunity In Every Difficulty.</q></p>
                        <h6 className='pb-4 text-sm italic'>- Amjad khan</h6> */}
                        <a href="https://www.youtube.com/@GrameenGyan/videos" className="">
                            <button className='group relative w-[130px] h-[35px] mt-[5%] rounded-[30px] bg-white border-[1px] border-[#cd201f] border-[solid] overflow-hidden'>
                                <span class="absolute top-[0] left-[0] [transition:transform_0.5s] before:absolute before:-left-[100px] before:top-[0] before:content-[''] before:w-[130px] before:h-[33px] before:rounded-[30px] before:bg-[#cd201f] group-hover:translate-x-[96px] group-hover:[transition:transform_0.5s]">
                                    <svg fill="#000" className='relative' height="33" viewBox="0 0 120 120" width="33">
                                        <path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path>
                                        <path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path>
                                        <path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path>
                                    </svg>
                                </span>
                                <span class="text-[15px] font-semibold ml-[22%]">Follow Us</span>
                                <span class="absolute top-1/2 -translate-y-1/2 -left-[50px] font-bold text-[14px] text-white group-hover:translate-x-[100px] group-hover:[transition:transform_0.6s]">1.15k</span>
                            </button>
                        </a>
                    </div>
                </div>
                <div className='lg:w-[60%]'>
                    <div class="example">
                        <div class="cursor-pointer ytOne hexagon -big" onClick={() => OpenModal("VideoOne")}></div>
                        <div class="cursor-pointer ytTwo hexagon -big" onClick={() => OpenModal("VideoTwo")}></div>
                        <div class="cursor-pointer ytThree hexagon -normal" onClick={() => OpenModal("VideoThree")}></div>
                        <div class="cursor-pointer ytFour hexagon -normal" onClick={() => OpenModal("VideoFour")}></div>
                        <div class="cursor-pointer ytFive hexagon -normal" onClick={() => OpenModal("VideoFive")}></div>
                        <div class="hexagon before:bg-gradient-to-tr from-[#FFECE2] to-[#FFFFFF] -small"></div>
                        <div class="hexagon before:bg-gradient-to-tr from-[#FFECE2] to-[#FFFFFF] -small"></div>
                        <div class="hexagon before:bg-gradient-to-tr from-[#FFECE2] to-[#FFFFFF] -small"></div>
                        <div class="hexagon before:bg-gradient-to-tr from-[#FFECE2] to-[#FFFFFF] -small"></div>
                    </div>
                </div>
                {videoOpen && (
                    <div className="fixed left-0 top-0 z-50 flex h-screen w-full items-center justify-center bg-black bg-opacity-70">
                        <div className="mx-auto w-full max-w-[80%] h-full max-h-[80%] bg-white">
                            <>
                                <iframe className="h-full w-full" src={videoUrl} />
                            </>
                        </div>

                        <button onClick={() => setvideoOpen(false)} className="absolute right-0 top-0 flex h-20 w-20 cursor-pointer items-center justify-center text-body-color hover:bg-black hover:text-white" >
                            <svg viewBox="0 0 16 15" className="h-8 w-8 fill-current">
                                <path d="M3.37258 1.27L8.23258 6.13L13.0726 1.29C13.1574 1.19972 13.2596 1.12749 13.373 1.07766C13.4864 1.02783 13.6087 1.00141 13.7326 1C13.9978 1 14.2522 1.10536 14.4397 1.29289C14.6272 1.48043 14.7326 1.73478 14.7326 2C14.7349 2.1226 14.7122 2.24439 14.6657 2.35788C14.6193 2.47138 14.5502 2.57419 14.4626 2.66L9.57258 7.5L14.4626 12.39C14.6274 12.5512 14.724 12.7696 14.7326 13C14.7326 13.2652 14.6272 13.5196 14.4397 13.7071C14.2522 13.8946 13.9978 14 13.7326 14C13.6051 14.0053 13.478 13.984 13.3592 13.9375C13.2404 13.8911 13.1326 13.8204 13.0426 13.73L8.23258 8.87L3.38258 13.72C3.29809 13.8073 3.19715 13.8769 3.08559 13.925C2.97402 13.9731 2.85405 13.9986 2.73258 14C2.46737 14 2.21301 13.8946 2.02548 13.7071C1.83794 13.5196 1.73258 13.2652 1.73258 13C1.73025 12.8774 1.753 12.7556 1.79943 12.6421C1.84586 12.5286 1.91499 12.4258 2.00258 12.34L6.89258 7.5L2.00258 2.61C1.83777 2.44876 1.74112 2.23041 1.73258 2C1.73258 1.73478 1.83794 1.48043 2.02548 1.29289C2.21301 1.10536 2.46737 1 2.73258 1C2.97258 1.003 3.20258 1.1 3.37258 1.27Z" />
                            </svg>
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HomePageVideoSection