import React, { useEffect, useState } from 'react'
import "../../Stylesheet/style.css";
// import logo from "../../../Assets/BrainstemLogoBG.png"
import logo from "../../../Assets/BrainstemNoB.png"

const Navbar = () => {
  const [bgColorNav, setBgColorNav] = useState("transparent")
  const [textColorNav, setTextColorNav] = useState("#74041c")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollY, setScrollY] = useState(0);
  const [open, setOpen] = useState(false);

  const pathEndUrl = window.location.pathname.split('/').pop();

  const navbarStyle = {
    position: 'fixed',
    // top: scrollY === 0 ? 0 : '-10%',
    top: 0,
    width: '100%',
    // padding: windowWidth > 1300 ? '1.5rem' : '1rem',
    backgroundColor: scrollY === 0 ? bgColorNav : 'white',
    // color: scrollY === 0 ? 'white' : 'black',
    color: 'black',
    // textColor: scrollY === 0 ? 'text-white' : 'text-black',
    textColor: 'text-black',
    zIndex: 50,
    transition: 'top 0.5s, background-color 0.5s',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // boxShadow: scrollY === 0 ? "none" : "0px 8px 16px 0px rgba(0, 0, 0, 0.2)"
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      // if (scrollY === 0) { setMenuStrokecolor("black") } else { setMenuStrokecolor("white") }
    };
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const ChangeStyle = () => {
    setBgColorNav("white")
    setTextColorNav("#74041c")
  }
  const ResetStyle = () => {
    setBgColorNav("transparent")
    setTextColorNav("black")
  }
  return (
    // <header className={`FontCinzel flex w-full items-center bg-white`} id="MainpageNavbar" style={navbarStyle} onMouseEnter={() => ChangeStyle()} onMouseLeave={() => ResetStyle()}>
    <header className=" sticky top-0 flex w-full justify-center items-center bg-white z-20 shadow-xl" id="MainpageNavbar">
      <div className="w-full px-[5%]">
        <div className="relative flex items-center justify-between">
          <a href='/' className="flex relative cursor-pointer">
            <div className="lg:block w-full py-5 flex justify-center items-center">
              <img src={logo} alt="logo" className="dark:hidden w-10 md:w-16" />
              <img src={logo} alt="logo" className="hidden dark:block w-10 md:w-16" />
            </div>
            <h2 className='hidden lg:flex font-beVietnamBold text-4xl absolute bottom-[20%] left-[4rem]'>BRAIN<span className='text-primary1 hover:text-primary2'>STEM</span></h2>
          </a>
          <div className="flex w-full items-center justify-end px-4">
            <div className=''>
              <button onClick={() => setOpen(!open)} id="navbarToggler" className={` ${open && "navbarTogglerActive"} absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`} >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color bg-black"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color bg-black"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color bg-black"></span>
              </button>
              <nav id="navbarCollapse" className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-${open ? 'white' : 'transparent'} px-6 py-5 shadow dark:bg-dark-2 lg:static lg:flex lg:items-end lg:w-full lg:max-w-full lg:shadow-none lg:dark:bg-transparent ${!open && "hidden"}`} >
                <ul className={`block lg:flex lg:items-end text-[${textColorNav}]`}>
                  <ListItem NavLink="/" isactive={pathEndUrl == "" && "underline"}>Home</ListItem>
                  <ListItem NavLink="/aboutus" isactive={pathEndUrl == "aboutus" && "underline"}>About Us</ListItem>
                  <ListItem NavLink="/services" isactive={pathEndUrl == "services" && "underline"}>Services</ListItem>
                  <ListItem NavLink="/media" isactive={pathEndUrl == "media" && "underline"}>Media</ListItem>
                  {/* <ListItem NavLink="/events">Events</ListItem> */}
                  <ListItem NavLink="/contactus" isactive={pathEndUrl == "contactus" && "underline"}>Contact Us</ListItem>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const ListItem = ({ children, NavLink, isactive }) => {
  return (
    <>
      <li>
        <a href={NavLink} className={`flex pt-2 text-lg font-beVietnam underline-offset-2 ${isactive && "text-primary2"} hover:text-primary2 lg:ml-12 lg:inline-flex`} >
          {children}
        </a>
      </li>
    </>
  );
};

export default Navbar