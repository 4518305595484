import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';
import { Fade, Slide } from 'react-swift-reveal';

const Contactus = () => {
  return (
    <div className='relative'>
      <section className="flex justify-center relative z-10 overflow-hidden py-20 lg:py-[120px]">
        <div className="container">
          <div className="-mx-4 flex flex-wrap lg:justify-between">
            <Fade delay={200}>
              <div className="w-full lg:w-1/2 xl:w-6/12 flex justify-center lg:justify-end">
                <div className="mb-12 max-w-[570px] lg:mb-0 p-8">
                  <span className="font-mono mb-4 block text-base text-[#74041c]">
                    CONTACT US
                  </span>
                  <h2 className="FontCinzel mb-6 text-[32px] font-bold uppercase text-dark text-black sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                    GET IN TOUCH WITH US
                  </h2>
                  <p className="mb-9 leading-relaxed">
                    Have a question or need assistance? We're here to help! Whether you're interested in our services, have a suggestion for improvement, or simply want to say hello, feel free to reach out to us. Our team is dedicated to providing exceptional service and support to our clients. Let's start a conversation today!
                  </p>
                  <div className=''>
                    <div className="mb-8 flex w-full max-w-[370px]">
                      <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill='#74041c'
                            d="M30.6 11.8002L17.7 3.5002C16.65 2.8502 15.3 2.8502 14.3 3.5002L1.39998 11.8002C0.899983 12.1502 0.749983 12.8502 1.04998 13.3502C1.39998 13.8502 2.09998 14.0002 2.59998 13.7002L3.44998 13.1502V25.8002C3.44998 27.5502 4.84998 28.9502 6.59998 28.9502H25.4C27.15 28.9502 28.55 27.5502 28.55 25.8002V13.1502L29.4 13.7002C29.6 13.8002 29.8 13.9002 30 13.9002C30.35 13.9002 30.75 13.7002 30.95 13.4002C31.3 12.8502 31.15 12.1502 30.6 11.8002ZM13.35 26.7502V18.5002C13.35 18.0002 13.75 17.6002 14.25 17.6002H17.75C18.25 17.6002 18.65 18.0002 18.65 18.5002V26.7502H13.35ZM26.3 25.8002C26.3 26.3002 25.9 26.7002 25.4 26.7002H20.9V18.5002C20.9 16.8002 19.5 15.4002 17.8 15.4002H14.3C12.6 15.4002 11.2 16.8002 11.2 18.5002V26.7502H6.69998C6.19998 26.7502 5.79998 26.3502 5.79998 25.8502V11.7002L15.5 5.4002C15.8 5.2002 16.2 5.2002 16.5 5.4002L26.3 11.7002V25.8002Z"
                          />
                        </svg>
                      </div>
                      <div className="w-full">
                        <h4 className="FontCinzel mb-1 text-xl font-bold text-dark">
                          Head Office
                        </h4>
                        <p className="FontMartel text-base text-body-color">
                          Spectrum Mall, Office no. 21, 6th floor, Sector 75, Noida - 201301
                        </p>
                      </div>
                    </div>

                    <div className="mb-8 flex w-full max-w-[370px]">
                      <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_941_17577)">
                            <path
                              d="M24.3 31.1499C22.95 31.1499 21.4 30.7999 19.7 30.1499C16.3 28.7999 12.55 26.1999 9.19997 22.8499C5.84997 19.4999 3.24997 15.7499 1.89997 12.2999C0.39997 8.59994 0.54997 5.54994 2.29997 3.84994C2.34997 3.79994 2.44997 3.74994 2.49997 3.69994L6.69997 1.19994C7.74997 0.599942 9.09997 0.899942 9.79997 1.89994L12.75 6.29994C13.45 7.34994 13.15 8.74994 12.15 9.44994L10.35 10.6999C11.65 12.7999 15.35 17.9499 21.25 21.6499L22.35 20.0499C23.2 18.8499 24.55 18.4999 25.65 19.2499L30.05 22.1999C31.05 22.8999 31.35 24.2499 30.75 25.2999L28.25 29.4999C28.2 29.5999 28.15 29.6499 28.1 29.6999C27.2 30.6499 25.9 31.1499 24.3 31.1499ZM3.79997 5.54994C2.84997 6.59994 2.89997 8.74994 3.99997 11.4999C5.24997 14.6499 7.64997 18.0999 10.8 21.2499C13.9 24.3499 17.4 26.7499 20.5 27.9999C23.2 29.0999 25.35 29.1499 26.45 28.1999L28.85 24.0999C28.85 24.0499 28.85 24.0499 28.85 23.9999L24.45 21.0499C24.45 21.0499 24.35 21.0999 24.25 21.2499L23.15 22.8499C22.45 23.8499 21.1 24.1499 20.1 23.4999C13.8 19.5999 9.89997 14.1499 8.49997 11.9499C7.84997 10.8999 8.09997 9.54994 9.09997 8.84994L10.9 7.59994V7.54994L7.94997 3.14994C7.94997 3.09994 7.89997 3.09994 7.84997 3.14994L3.79997 5.54994Z"
                              fill='#74041c'
                            />
                            <path
                              d="M29.3 14.25C28.7 14.25 28.25 13.8 28.2 13.2C27.8 8.15003 23.65 4.10003 18.55 3.75003C17.95 3.70003 17.45 3.20003 17.5 2.55003C17.55 1.95003 18.05 1.45003 18.7 1.50003C24.9 1.90003 29.95 6.80003 30.45 13C30.5 13.6 30.05 14.15 29.4 14.2C29.4 14.25 29.35 14.25 29.3 14.25Z"
                              fill='#74041c'
                            />
                            <path
                              d="M24.35 14.7002C23.8 14.7002 23.3 14.3002 23.25 13.7002C22.95 11.0002 20.85 8.90018 18.15 8.55018C17.55 8.50018 17.1 7.90018 17.15 7.30018C17.2 6.70018 17.8 6.25018 18.4 6.30018C22.15 6.75018 25.05 9.65018 25.5 13.4002C25.55 14.0002 25.15 14.5502 24.5 14.6502C24.4 14.7002 24.35 14.7002 24.35 14.7002Z"
                              fill='#74041c'
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_941_17577">
                              <rect width="32" height="32" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="w-full">
                        <h4 className="FontCinzel mb-1 text-xl font-bold text-dark">
                          Contact
                        </h4>
                        <p className="FontMartel text-base text-body-color">
                          &#40;+91&#41; 94244&#32;15981
                        </p>
                      </div>
                    </div>

                    <div className="mb-8 flex w-full max-w-[370px]">
                      <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                            fill='#74041c'
                          />
                        </svg>
                      </div>
                      <div className="w-full">
                        <h4 className="FontCinzel mb-1 text-xl font-bold text-dark">
                          Email
                        </h4>
                        <p className="FontMartel text-base text-body-color">
                          brainstemllp16@gmail.com<br />
                          amjusha@gmail.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-4 w-full lg:w-1/2 flex justify-center'>
                <div className="w-[90%] md:w-[80%] p-4">
                  <div className="relative rounded-lg bg-white p-8 shadow-lg sm:p-12">
                    <form action="https://public.herotofu.com/v1/8b9c6dc0-a04f-11ef-ac0a-634f4b14fdcf" method="post" accept-charset="UTF-8">
                      <ContactInputBox
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required={true}
                      />
                      <ContactInputBox
                        type="text"
                        name="email"
                        placeholder="Your Email"
                        required={true}
                      />
                      <ContactInputBox
                        type="text"
                        name="phone"
                        placeholder="Your Phone"
                        required={false}
                      />
                      <ContactTextArea
                        row="6"
                        placeholder="Your Message"
                        name="details"
                        require={true}
                      />
                      <div>
                        <button
                          type="submit"
                          className="w-full rounded border border-primary bg-primary p-3 transition hover:bg-opacity-90"
                        >
                          Send Message
                        </button>
                      </div>
                    </form>
                    <div>
                      <span className="absolute -right-9 -top-10 z-[-1]">
                        <svg
                          width={100}
                          height={100}
                          viewBox="0 0 100 100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                            fill="#e4641b"
                          />
                        </svg>
                      </span>
                      <span className="absolute -right-10 top-[90px] z-[-1]">
                        <svg
                          width={34}
                          height={134}
                          viewBox="0 0 34 134"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="31.9993"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 31.9993 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 31.9993 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 31.9993 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 31.9993 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 31.9993 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 31.9993 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 31.9993 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 31.9993 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 31.9993 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 31.9993 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 17.3333 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 17.3333 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 17.3333 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 17.3333 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 17.3333 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 17.3333 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 17.3333 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 17.3333 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 17.3333 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 17.3333 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 2.66536 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 2.66536 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 2.66536 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 2.66536 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 2.66536 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 2.66536 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 2.66536 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 2.66536 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 2.66536 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 2.66536 1.66665)"
                            fill="#13C296"
                          />
                        </svg>
                      </span>
                      <span className="absolute -bottom-7 -left-7 z-[-1]">
                        <svg
                          width={107}
                          height={134}
                          viewBox="0 0 107 134"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="104.999"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 104.999 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 104.999 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 104.999 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 104.999 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 104.999 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 104.999 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 104.999 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 104.999 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 104.999 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="104.999"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 104.999 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 90.3333 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 90.3333 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 90.3333 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 90.3333 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 90.3333 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 90.3333 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 90.3333 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 90.3333 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 90.3333 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="90.3333"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 90.3333 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 75.6654 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 31.9993 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 75.6654 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 31.9993 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 75.6654 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 31.9993 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 75.6654 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 31.9993 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 75.6654 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 31.9993 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 75.6654 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 31.9993 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 75.6654 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 31.9993 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 75.6654 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 31.9993 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 75.6654 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 31.9993 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="75.6654"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 75.6654 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="31.9993"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 31.9993 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 60.9993 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 17.3333 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 60.9993 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 17.3333 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 60.9993 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 17.3333 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 60.9993 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 17.3333 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 60.9993 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 17.3333 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 60.9993 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 17.3333 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 60.9993 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 17.3333 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 60.9993 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 17.3333 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 60.9993 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 17.3333 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="60.9993"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 60.9993 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="17.3333"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 17.3333 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 46.3333 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={132}
                            r="1.66667"
                            transform="rotate(180 2.66536 132)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 46.3333 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="117.333"
                            r="1.66667"
                            transform="rotate(180 2.66536 117.333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 46.3333 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="102.667"
                            r="1.66667"
                            transform="rotate(180 2.66536 102.667)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 46.3333 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={88}
                            r="1.66667"
                            transform="rotate(180 2.66536 88)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 46.3333 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="73.3333"
                            r="1.66667"
                            transform="rotate(180 2.66536 73.3333)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 46.3333 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={45}
                            r="1.66667"
                            transform="rotate(180 2.66536 45)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 46.3333 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={16}
                            r="1.66667"
                            transform="rotate(180 2.66536 16)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 46.3333 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy={59}
                            r="1.66667"
                            transform="rotate(180 2.66536 59)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 46.3333 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="30.6666"
                            r="1.66667"
                            transform="rotate(180 2.66536 30.6666)"
                            fill="#13C296"
                          />
                          <circle
                            cx="46.3333"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 46.3333 1.66665)"
                            fill="#13C296"
                          />
                          <circle
                            cx="2.66536"
                            cy="1.66665"
                            r="1.66667"
                            transform="rotate(180 2.66536 1.66665)"
                            fill="#13C296"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section >
      {/* second section  */}
      <div className=' flex justify-center items-center py-8 bg-gray-100'>
        <div className='md:w-[80%] md:flex '>
          <Slide left>
            <div className='md:w-1/2 space-y-6 p-4'>
              <span className='FontMartel text-3xl text-[#74041c]'>Visit our office</span>
              <p>If you'd like to meet us in person, we'd love to welcome you to our office! Our team is ready to assist you with any questions or inquiries you may have. Please feel free to drop by during our business hours.</p>
              <button className='font-mono border border-[#74041c] px-5 py-2 rounded-full text-sm hover:bg-[#74041c] hover:text-white duration-500'>GET DIRECTION</button>
            </div>
          </Slide>
          <Slide right>
            <div className='md:w-1/2 flex justify-center lg:justify-end items-center mt-8 md:mt-0'>
              <div className='w-[20rem] h-[20rem] bg-white rounded-2xl flex justify-center items-center relative'>
                <div className='absolute -top-5 bg-[#74041c] rounded-full px-5 py-2 text-white'>Open Hours</div>
                <div className='space-y-8'>
                  <div className='flex flex-col justify-center items-center'>
                    <span className=''>Monday - Saturday</span><br />
                    <span className='text-[#74041c]'>9am - 6pm</span>
                  </div>
                  <div className='flex flex-col justify-center items-center'>
                    <span className=''>Sunday</span><br />
                    <span className='text-[#74041c]'>10am - 6pm</span>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      {/* third section  */}
      <div className=' flex justify-center items-center py-8'>
        <div className='md:w-[80%] md:flex items-center'>
          <Slide left delay={300}>
            <div className='md:w-1/2 p-4'>
              <h2 className='font-mono text-[#74041c] uppercase'>Frequently asked questions</h2>
              <p className='py-3'>Have questions? We've got answers! Check out some of the most commonly asked questions below. If you don't find what you're looking for, feel free to reach out to us directly.</p>
              <div className='p-4 md:p-0'>
                <div class="mx-auto max-w-lg ">
                  <div class="divide-y divide-gray-100">
                    <details class="group">
                      <summary
                        class="flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium text-secondary-900 group-open:text-primary-500">
                        What services does your consulting organization offer?
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="block h-5 w-5 group-open:hidden">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="hidden h-5 w-5 group-open:block">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                          </svg>
                        </div>
                      </summary>
                      <div class="pb-4 text-secondary-500">We provide a range of services aimed at strengthening the capacity of groups, collectives, and promoters of institutions. Our offerings include capacity building, strategic support to develop enabling ecosystems, comprehensive and customized business solutions, and on-site operational assistance.</div>
                    </details>
                    <details class="group">
                      <summary
                        class="flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium text-secondary-900 group-open:text-primary-500">
                        How do you approach providing strategic support?
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="block h-5 w-5 group-open:hidden">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="hidden h-5 w-5 group-open:block">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                          </svg>
                        </div>
                      </summary>
                      <div class="pb-4 text-secondary-500">Our approach to strategic support involves working closely with clients to understand their unique challenges and opportunities. We collaborate with key stakeholders to develop strategic plans, identify growth opportunities, and implement actionable solutions that drive sustainable results.</div>
                    </details>
                    <details class="group">
                      <summary
                        class="flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium text-secondary-900 group-open:text-primary-500">
                        How do I get started with your consulting services?
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="block h-5 w-5 group-open:hidden">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="hidden h-5 w-5 group-open:block">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                          </svg>
                        </div>
                      </summary>
                      <div class="pb-4 text-secondary-500">Getting started with our consulting services is easy. Simply reach out to us to schedule an initial consultation. During this meeting, we'll discuss your organization's needs and objectives, and tailor a solution that meets your requirements. From there, we'll work closely with you to implement the necessary strategies and support you every step of the way.</div>
                    </details>
                    <details class="group">
                      <summary
                        class="flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium text-secondary-900 group-open:text-primary-500">
                        How can your consulting services benefit my organization?
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="block h-5 w-5 group-open:hidden">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="hidden h-5 w-5 group-open:block">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                          </svg>
                        </div>
                      </summary>
                      <div class="pb-4 text-secondary-500">Our consulting services are designed to empower your organization by enhancing its capacity and efficiency. Whether you're seeking to develop a strategic roadmap, streamline operations, or foster innovation, we offer tailored solutions to address your specific needs and goals.</div>
                    </details>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <Slide right delay={400}>
            <div className='md:w-1/2 flex flex-col lg:items-end pb-6 px-4'>
              <h2 className='text-2xl font-bold py-4 text-center w-full'>Ask a different question</h2>
              <form className='space-y-4 w-full md:w-[80%]' action="https://public.herotofu.com/v1/1588a800-a050-11ef-a0ee-650b92707733" method="post" accept-charset="UTF-8">
                <input type='text' placeholder='Name' name='Name' required className='p-2 md:p-4 w-full outline-none border-b-2 focus:border-black' /><br />
                <input type='email' placeholder='Email' name='Email' required className='p-2 md:p-4 w-full outline-none border-b-2 focus:border-black' /><br />
                <textarea rows={3} placeholder='Your Question' name='Question' required className='p-2 md:p-4 w-full outline-none border-b-2 focus:border-black' /><br />
                <button type='submit' className='bg-[#74041c] text-sm rounded-lg px-4 py-1 text-white'>Submit</button>
              </form>
            </div>
          </Slide>
        </div>
      </div>
    </div >
  )
}

const ContactTextArea = ({ row, placeholder, name, defaultValue, require }) => {
  return (
    <>
      <div className="mb-6">
        <textarea
          rows={row}
          placeholder={placeholder}
          name={name}
          required={require}
          className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"
          defaultValue={defaultValue}
        />
      </div>
    </>
  );
};

const ContactInputBox = ({ type, placeholder, name, required }) => {
  return (
    <>
      <div className="mb-6">
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          required={required}
          className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary"
        />
      </div>
    </>
  );
};


export default Contactus