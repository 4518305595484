import React from 'react'
import CountUp from 'react-countup'
import { Fade, Slide } from 'react-swift-reveal'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import mapStat from "../../Assets/props/map.avif"
import mapStat from "../../Assets/map/reachmap.png"
import StatAssam from "../../Assets/map/assam.png"
import StatJharkhand from "../../Assets/map/jharkhand.png"
import StatTripura from "../../Assets/map/meghalayatripura.png"
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Marquee from 'react-fast-marquee'
import abooutusbanner from "../../Assets/aboutusBanner.avif"
import abooutusExpertise1 from "../../Assets/aboutus/GROOMING_RURAL_DEVELOPMENT_PROFESSIONALS.png"
import abooutusExpertise2 from "../../Assets/aboutus/PRODUCER_GROUPS_AND_COLLECTIVES.png"
import abooutusExpertise3 from "../../Assets/aboutus/UNDERSTAND_AND_WORK_WITH_GROUPS.png"
import abooutusExpertise4 from "../../Assets/aboutus/ENTREPRENEURSHIP_ENHANCEMENT.png"
import abooutusExpertise5 from "../../Assets/aboutus/PROCESS_AND_PROGRAMME_EVALUATIONS.png"
import client1 from "../../Assets/clients/cini.jpeg"
// import client1 from "../../Assets/clients/CINI.png"
import client2 from "../../Assets/imago.png"
import client3 from "../../Assets/clients/MSRLS.png"
import client4 from "../../Assets/clients/MizoramSRLM.png"
import client5 from "../../Assets/clients/PARMARTH.jpg"
// import client5 from "../../Assets/clients/PARMARTH.png"
import client6 from "../../Assets/clients/SAMARTHAN.png"
import client7 from "../../Assets/clients/SeSTA.png"
import client8 from "../../Assets/clients/TRLM.png"
import client9 from "../../Assets/clients/TRI.png"
import client10 from "../../Assets/clients/syngenta.png"
import { Link } from 'react-router-dom'
import Teamsection from '../section/Teamsection'

const Aboutus = () => {
  return (
    <div>
      <section class="py-16 overflow-hidden">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap mb-32 mt-14 -mx-8">
            <div class="w-full lg:w-1/2 px-8 flex justify-center">
              <img class="rounded-3xl w-3/4 mb-8" src={abooutusbanner} alt="" />
            </div>
            <div class="w-full lg:w-1/2 px-8">
              <h1 class="text-5xl lg:text-7xl font-bold font-heading mb-10 max-w-xs lg:max-w-lg"><span className='text-primary1'>Our</span> Team</h1>
              <p class="text-gray-600 text-lg">We are a team of professionals who have strategic and technical skills honed through years of working with in strengthening capacity of groups, collectives and promoters of such institutions to identify, understand and respond to the needs of such institutions.</p>
            </div>
          </div>
          {/* about company section  */}
          <div className='py-10 bg-gray-50 rounded-xl'>
            <h2 className='FontMartel text-center text-3xl font-bold uppercase'>Who we are</h2>
            <div className='flex justify-center py-4'>
              <Fade>
                <p className='FontGotham w-[95%] text-center'>BRAINSTEM is a new generation consulting organisation promoted by group of professionals who has years of experience in strengthening capacity of groups, collectives and promoters of such institutions. BRAINSTEM provides capacity building and strategic support to develop enabling eco-systems; comprehensive, customised business solutions; and on-site operational assistance. The core strength areas lie in designing and delivering participatory training modules, support in developing organizational processes, business promotion and external linkages, and evaluation of programs and processes.</p>
              </Fade>
            </div>
          </div>
          <div></div>
          {/* counter section */}
          <div class="border border-gray-200 bg-white rounded-3xl flex flex-wrap my-32">
            <div class="w-full md:w-1/2 py-8">
              <div class="md:border-r border-gray-200 px-12">
                <p class="text-gray-600 mb-2 text-center">Team Members</p>
                <h2 class="text-4xl lg:text-5xl font-semibold text-center"><CountUp end={3} enableScrollSpy></CountUp></h2>
              </div>
            </div>
            {/* <div class="w-full md:w-1/2 lg:w-1/4 py-8">
              <div class="lg:border-r border-gray-200 px-12">
                <p class="text-gray-600 mb-2 text-center">Total funding</p>
                <h2 class="text-4xl lg:text-5xl font-semibold text-center">$<CountUp end={9} enableScrollSpy></CountUp>.6 M</h2>
              </div>
            </div> */}
            <div class="w-full md:w-1/2 py-8">
              <div class=" border-gray-200 px-12">
                <p class="text-gray-600 mb-2 text-center">Consultants</p>
                <h2 class="text-4xl lg:text-5xl font-semibold text-center"><CountUp end={10} enableScrollSpy></CountUp>+</h2>
              </div>
            </div>
            {/* <div class="w-full md:w-1/2 lg:w-1/3 py-8">
              <div class="px-12">
                <p class="text-gray-600 mb-2 text-center">Nationalities</p>
                <h2 class="text-4xl lg:text-5xl font-semibold text-center"><CountUp end={24} enableScrollSpy></CountUp></h2>
              </div>
            </div> */}
          </div>
          {/* values section  */}
          <div class="flex flex-wrap">
            <div class="w-full">
              <h2 class="cursor-default text-3xl lg:text-5xl font-bold font-heading mb-20 max-w-xs lg:max-w-lg"> Areas of <span className='text-primary1'>Expertise</span></h2>
              <div className='md:flex'>
                <Slide left>
                  <div className='w-full md:w-[40%] flex justify-center items-center'><img class="rounded-3xl w-[70%]" src={abooutusExpertise1} alt="" /></div>
                </Slide>
                <Fade delay={600}>
                  <div className='w-full md:w-[60%] flex flex-col justify-center items-center p-8'>
                    <h2 className='text-2xl self-start py-2'>GROOMING RURAL DEVELOPMENT PROFESSIONALS</h2>
                    <p class="text-gray-600 text-lg">Includes Rural induction, Learning by doing, Training of trainers in group processes, livelihoods, process evaluation, Fundamentals of collectives and Entrepreneurship.</p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className='md:flex md:flex-row-reverse py-8'>
            <Slide right>
              <div className='w-full md:w-[40%] flex justify-center items-center'><img class="rounded-3xl w-[80%]" src={abooutusExpertise2} alt="" /></div>
            </Slide>
            <Fade delay={600}>
              <div className='w-full md:w-[60%] flex flex-col justify-center items-center p-8'>
                <h2 className='text-2xl self-start py-2'>PRODUCER GROUPS AND COLLECTIVES</h2>
                <p class="text-gray-600 text-lg">Includes Visioning, Leadership development, Business planning, Designing operational structure and systems etc.</p>
              </div>
            </Fade>
          </div>
          <div className='md:flex py-8'>
            <Slide left>
              <div className='w-full md:w-[40%] flex justify-center items-center'><img class="rounded-3xl w-[90%]" src={abooutusExpertise3} alt="" /></div>
            </Slide>
            <Fade delay={600}>
              <div className='w-full md:w-[60%] flex flex-col justify-center items-center p-8'>
                <h2 className='text-2xl self-start py-2'>UNDERSTAND AND WORK WITH GROUPS</h2>
                <p class="text-gray-600 text-lg">Includes Group Formation Process Design, Group Nurturing and Facilitation, Group Processes facilitation trainings such as; Communication, Decision-making, Problem Solving, and Leadership.  Group health analysis tools e.g. Group Health inventory, VO Readiness Tool, Federation Health Mapping Tools and Process Evaluation.</p>
              </div>
            </Fade>
          </div>
          <div className='md:flex md:flex-row-reverse py-8'>
            <Slide right>
              <div className='w-full md:w-[40%] flex justify-center items-center'><img class="rounded-3xl w-[80%]" src={abooutusExpertise4} alt="" /></div>
            </Slide>
            <Fade delay={600}>
              <div className='w-full md:w-[60%] flex flex-col justify-center items-center p-8'>
                <h2 className='text-2xl self-start py-2'>ENTREPRENEURSHIP ENHANCEMENT</h2>
                <p class="text-gray-600 text-lg">Includes Visioning and Motivation, Basics of Business, Business Plan preparation, Fund Mobilization, System Setting for member owned business entities and individual.</p>
              </div>
            </Fade>
          </div>
          <div className='md:flex py-8'>
            <Slide left>
              <div className='w-full md:w-[40%] flex justify-center items-center'><img class="rounded-3xl w-full" src={abooutusExpertise5} alt="" /></div>
            </Slide>
            <Fade delay={600}>
              <div className='w-full md:w-[60%] flex flex-col justify-center items-center p-8'>
                <h2 className='text-2xl self-start py-2'>PROCESS AND PROGRAMME EVALUATIONS</h2>
                <p class="text-gray-600 text-lg">Includes Group Health Inventory, Producer’s Collective Health Tool, and Member based Institutions such as Federation’s Grading and Process Evaluation, Impact Studies and General Project Assessments.</p>
              </div>
            </Fade>
          </div>
          {/* map section  */}
          <div className='md:flex md:flex-row-reverse my-8'>
            <div className='md:w-1/2 flex justify-center'>
              <LazyLoadImage src={mapStat} alt='map statistics' className='md:w-[450px]' />
            </div>
            <div className='md:w-1/2 flex justify-center items-center'>
              <div className='FontCinzel text-2xl md:text-4xl font-bold'>
                Our <span className='text-primary1'>Reach</span>
              </div>
            </div>
          </div>
          {/* investor section  */}
          <h2 class="text-center text-4xl lg:text-5xl mb-9 font-bold font-heading text-primary1">Our Clients</h2>
          <Marquee className='py-4' pauseOnHover>
            <Link to="https://cinicell.org/"><img src={client1} alt='' width={150} className='mx-12' /></Link>
            <Link to="https://imagogg.org/"><img src={client2} alt='' width={250} className='mx-12' /></Link>
            <Link to="https://msrls.nic.in/"><img src={client3} alt='' width={400} className='mx-12' /></Link>
            <Link to="https://srlm.mizoram.gov.in/"><img src={client4} alt='' width={100} className='mx-12' /></Link>
            <Link to="https://parmarthindia.com/"><img src={client5} alt='' width={100} className='mx-12' /></Link>
            <Link to="https://samarthan.org/"><img src={client6} alt='' width={250} className='mx-12' /></Link>
            <Link to="https://sesta.org/"><img src={client7} alt='' width={250} className='mx-12' /></Link>
            <Link to="https://trlm.tripura.gov.in/"><img src={client8} alt='' width={350} className='mx-12' /></Link>
            <Link to="https://www.trif.in/"><img src={client9} alt='' width={200} className='mx-12' /></Link>
            <Link to="https://www.syngenta.com/en"><img src={client10} alt='' width={250} className='mx-12' /></Link>
          </Marquee>
          {/* <div class="bg-gray-50 rounded-3xl mb-28 flex flex-wrap py-8">
            <div class="w-full md:w-1/2 lg:w-1/3 py-8 px-16 flex justify-center items-center">
              <img src="https://i.ibb.co/Cnwd4q6/image-01.jpg" alt="" />
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 py-8 px-16 flex justify-center items-center">
              <img src="https://i.ibb.co/Cnwd4q6/image-01.jpg" alt="" />
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 py-8 px-16 flex justify-center items-center">
              <img src="https://i.ibb.co/Cnwd4q6/image-01.jpg" alt="" />
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 py-8 px-16 flex justify-center items-center">
              <img src="https://i.ibb.co/Cnwd4q6/image-01.jpg" alt="" />
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 py-8 px-16 flex justify-center items-center">
              <img src="https://i.ibb.co/Cnwd4q6/image-01.jpg" alt="" />
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 py-8 px-16 flex justify-center items-center">
              <img src="https://i.ibb.co/Cnwd4q6/image-01.jpg" alt="" />
            </div>
          </div> */}
          {/* team section */}
          <section class="py-24 ">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div class="mb-12">
                <h2 class="font-manrope text-5xl text-center font-bold text-primary1">Our Team</h2>
                <p className='py-4 text-lg text-center'>We are a team of professionals who have strategic and technical skills honed through years of working with in strengthening capacity of groups, collectives and promoters of such institutions to identify, understand and respond to the needs of such institutions.</p>
              </div>
              <Teamsection />
            </div>
          </section>
          <span class="relative flex justify-center py-4">
            <div class="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"></div>
          </span>
          {/* Experiences  */}
          <h2 class="text-3xl lg:text-5xl font-bold font-heading py-1 text-primary1">Past Experiences</h2>
          <div className='py-4 md:flex'>
            <div className='md:w-1/2'>
              <h3 className='text-xl md:text-2xl py-4 text-primary2'>Mentorship of Agri-preneurs on business systems in Jharkhand</h3>
              <p className='text-sm md:text-base'>An effective input-output supply chain (both private & public) in farm sector in India is non-existent or broken. One of the reasons for this is fragmented atomistic production system which does not generate surplus at household level, being bereft of any mechanism for aggregation. Agri-preneurship is a market based response to mitigate some of these issues.
                <br />Skills, passion and efforts will be required from women entrepreneurs to dip into natural or created farm aggregations, linkages for capital, input supplies, outputs and most importantly provide a unique missing service which in the context of production system cannot otherwise be provided.
                <br />Brainstem has been supporting Syngenta Foundation in its joint project with TATA TRUSTS and Transform Rural India (TRI) to launch 100 village-based agri-preneurs in Jharkhand. The focus on engagement is in 6 Blocks in Jharkhand to Support capacity building and mentorship for Agri-preneurs on business systems. Brainstem supports village-based women entrepreneurs to enhance understanding on the process for selecting business ideas, skills on exploring business opportunities, business planning, business linkages and business hand-holding support. In this partnership, Brainstem is foremost in creating useful applicable knowledge and systemic linkages which will evolve as a process guide for replication.
              </p>
            </div>
            <div className='md:w-1/2 flex justify-center items-center'>
              <img src={StatJharkhand} alt='' width={350} />
            </div>
          </div>
          <div className='py-4 md:flex md:flex-row-reverse'>
            <div className='md:w-1/2'>
              <h3 className='text-xl md:text-2xl py-4 text-primary2'>Business facilitation support to Women Producers company in Assam</h3>
              <p className='text-sm md:text-base'>Brainstem has been providing Business facilitation support to Women Producers Company in Assam promoted under Mahila Kishan Sashaktikaran Pariyojana (MKSP) and project supported by Azim Premji Philanthropic Initiatives (APPI). Brainstem provides technical support services to SeSTA (Seven Sisters Development Assistance), a not for profit organisation which aims to catalyse development activities in the North East region by working to develop improved livelihood systems and better wellbeing status of rural communities.
                <br />This engagement is focussed towards developing a vision for the Producers collective owned by 2000 women members, support in developing the business plan and corresponding operating structures, systems and processes, designing training modules for collectives- to enhance ownership and understanding of the members towards collectives. In the process, Brainstem builds the capacity of the SeSTA team to take the learning to larger number of collectives in Assam.
              </p>
            </div>
            <div className='md:w-1/2 flex justify-center items-center'>
              <img src={StatAssam} alt='' width={350} />
            </div>
          </div>
          <div className='py-4 md:flex'>
            <div className='md:w-1/2'>
              <h3 className='text-xl md:text-2xl py-4 text-primary2'>Capacity building and Strategic support to State Rural Livelihoods Mission Tripura and Meghalaya</h3>
              <p className='text-sm md:text-base'>Brainstem offers strategic and capacity-building support to the Tripura and Meghalaya State Rural Livelihoods Mission, focusing on promoting Women Federations across various districts. The organization plays a crucial role in strategic planning for cluster-level federation building, including visioning, leadership development, and business planning. As a consulting organization comprised of experienced professionals, Brainstem provides customized business solutions, on-site operational assistance, and participatory training modules. It also aids in developing organizational processes, promoting businesses, fostering external linkages, and evaluating programs and processes to create enabling ecosystems for rural livelihoods.
              </p>
            </div>
            <div className='md:w-1/2 flex justify-center items-center'>
              <img src={StatTripura} alt='' width={350} />
            </div>
          </div>
        </div>
      </section >
    </div >
  )
}

const TeamMember = ({ memberProfileImg, memberName, memberPost, memberAbout, twitterlink, instagramlink, linkedinlink }) => {
  return (
    <div class="block group rounded p-10 border max-w-[20rem]">
      <div class="relative mb-6">
        <img src={memberProfileImg} alt="Antonio" class="w-40 h-40 rounded-lg mx-auto transition-all duration-500 object-cover border border-solid border-transparent group-hover:border-[#74141c]" />
      </div>
      <h4 class="text-xl font-semibold text-gray-900 mb-2 capitalize text-center transition-all duration-500 group-hover:text-[#74041c]">{memberName}</h4>
      <span class="text-gray-500 text-center block transition-all duration-500 group-hover:text-gray-900">{memberPost}</span>
      <div className='py-3 flex justify-center'>
        <div className='w-fit flex gap-3'>
          <a href={twitterlink} target='_blank' rel='noreferrer'><XIcon fontSize='small' className='hover:text-gray-700 hover:-translate-y-1' /></a>
          <a href={linkedinlink} target='_blank' rel='noreferrer'><LinkedInIcon fontSize='small' className='hover:text-sky-700 hover:-translate-y-1' /></a>
          <a href={instagramlink} target='_blank' rel='noreferrer'><InstagramIcon fontSize='small' className='hover:text-pink-600 hover:-translate-y-1' /></a>
        </div>
      </div>
      <p className='text-wrap text-center text-xs'>{memberAbout}</p>
    </div>
  )
}

export default Aboutus