import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPage from './Container/LandingPage/LandingPage'
import ErrrorPage from './Container/ErrorPage/ErrrorPage'
import Test from './Container/Test/Test'
import Media from './Container/Media/Media'
import Aboutus from './Container/Aboutus/Aboutus'
import Contactus from './Container/Contactus/Contactus'
import Events from './Container/Events/Events'
import PrivacyPolicy from './Container/Pages/PrivacyPolicy'
import TermsCondition from './Container/Pages/TermsCondition'
import Legal from './Container/Pages/Legal'
import Services from './Container/Services/Services'

const Approutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<LandingPage />} path='/' />
                <Route element={<ErrrorPage />} path='*' />
                {/* <Route element={<Test />} path='/test' /> */}
                <Route element={<Media />} path='/media' />
                {/* <Route element={<Events />} path='/events'/> */}
                <Route element={<Services />} path='/services' />
                <Route element={<Aboutus />} path='/aboutus' />
                <Route element={<Contactus />} path='/contactus' />
                <Route element={<PrivacyPolicy />} path='/privacy-policy' />
                <Route element={<Legal />} path='/legal' />
                <Route element={<TermsCondition />} path='/terms-and-conditions' />
            </Routes>
        </BrowserRouter>
    )
}

export default Approutes